<script setup>
import { computed } from 'vue';
import ChassisSectionFrameAgreement from './ChassisSectionFrameAgreement';
import ChassisSectionStockVehicle from './ChassisSectionStockVehicle';
import ChassisSectionStandard from './ChassisSectionStandard';

const props = defineProps({
  items: {
    type: Object,
    default: null,
  },
  images: {
    type: Object,
    required: true,
  },
  offerTemplate: {
    type: String,
    default: '',
    required: true,
  },
  specType: {
    type: String,
    default: '',
    required: true,
  },
  isFrameAgreement: {
    type: Boolean,
    default: false,
    required: true,
  },
  chassisDescriptionKey: {
    type: String,
    required: false,
    default: '',
  },
});

const isSpecEB = computed(() => {
  return props.offerTemplate === `${String(store.market).toUpperCase()}_STANDARDA4X2EB`;
});

const checkIfChassisSectionFrameAgreement = computed(() => {
  return props.isFrameAgreement;
});

const checkIfChassisSectionStockVehicle = computed(() => {
  return props.specType === 'stock_vehicle';
});

const checkIfChassisSectionStandard = computed(() => {
  return props.specType !== 'stock_vehicle';
});
</script>

<template>
  <ChassisSectionFrameAgreement
    v-if="checkIfChassisSectionFrameAgreement"
    :items="props.items"
    :images="props.images"
    :is-spec-e-b="isSpecEB"
  />
  <ChassisSectionStockVehicle
    v-else-if="checkIfChassisSectionStockVehicle"
    :items="props.items"
    :images="props.images"
    :is-spec-e-b="isSpecEB"
  />
  <ChassisSectionStandard
    v-else-if="checkIfChassisSectionStandard"
    :items="props.items"
    :images="props.images"
    :is-spec-e-b="isSpecEB"
    :chassis-description-key="props.chassisDescriptionKey"
  />
</template>

<style scoped>
</style>
