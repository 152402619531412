<script setup>
import { computed } from 'vue';
import StylingAndComfortFrameAgreement from './StylingAndComfortFrameAgreement';
import StylingAndComfortStockVehicle from './StylingAndComfortStockVehicle';
import StylingAndComfortStandard from './StylingAndComfortStandard';

const props = defineProps({
  items: {
    type: Object,
    default: null,
  },
  images: {
    type: Object,
    required: true,
  },
  offerTemplate: {
    type: String,
    default: '',
    required: true,
  },
  specType: {
    type: String,
    default: '',
    required: true,
  },
  isFrameAgreement: {
    type: Boolean,
    default: false,
    required: true,
  },
});

const isSpecEB = computed(() => {
  return props.offerTemplate === `${String(store.market).toUpperCase()}_STANDARDA4X2EB`;
});

const checkIfStylingAndComfortFrameAgreement = computed(() => {
  return props.isFrameAgreement;
});

const checkIfStylingAndComfortStockVehicle = computed(() => {
  return props.specType === 'stock_vehicle';
});

const checkIfStylingAndComfortStandard = computed(() => {
  return props.specType !== 'stock_vehicle';
});
</script>

<template>
  <StylingAndComfortFrameAgreement
    v-if="checkIfStylingAndComfortFrameAgreement"
    :items="props.items"
    :images="props.images"
    :is-spec-e-b="isSpecEB"
  />
  <StylingAndComfortStockVehicle
    v-else-if="checkIfStylingAndComfortStockVehicle"
    :items="props.items"
    :images="props.images"
    :is-spec-e-b="isSpecEB"
  />
  <StylingAndComfortStandard
    v-else-if="checkIfStylingAndComfortStandard"
    :items="props.items"
    :images="props.images"
    :is-spec-e-b="isSpecEB"
  />
</template>

<style scoped>
</style>
